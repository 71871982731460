import React from "react";
import { AppBar, Toolbar, Box } from "@mui/material";

const Header = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <img
            src="https://leads.shyftdigitally.com/wp-content/uploads/2024/04/Shyft-1024x423-1.png"
            alt="Logo"
            style={{ height: "50px" }}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
