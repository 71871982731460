import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";

const services = ["Website Development", "SEO", "Graphics Designs", "Ads", "Email Marketing"];
const offices = ["Canada", "Lahore", "Faisalabad"];

const EditClientDialog = ({ isOpen, onClose, onSave, client }) => {
  const [clientData, setClientData] = useState({ ...client });

  useEffect(() => {
    setClientData({ ...client });
  }, [client]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData({
      ...clientData,
      [name]: value,
    });
  };

  const handleSave = () => {
    onSave(clientData);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Client</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Client Name"
            type="text"
            fullWidth
            variant="outlined"
            value={clientData.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Client Email"
            type="email"
            fullWidth
            variant="outlined"
            value={clientData.email}
            onChange={handleChange}
          />
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel>Service</InputLabel>
            <Select
              name="service"
              value={clientData.service}
              onChange={handleChange}
              label="Service"
            >
              {services.map((service) => (
                <MenuItem key={service} value={service}>
                  {service}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel>Office</InputLabel>
            <Select name="office" value={clientData.office} onChange={handleChange} label="Office">
              {offices.map((office) => (
                <MenuItem key={office} value={office}>
                  {office}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            name="date"
            label="Date"
            type="date"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={clientData.date}
            onChange={handleChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditClientDialog;
