import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const ClientList = ({ clients, onEdit, onDelete }) => {
  return (
    <TableContainer component={Paper} style={{ marginTop: "20px" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h6">Name</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Email</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Service</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Office</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Date</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Actions</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clients.map((client) => (
            <TableRow key={client.id}>
              <TableCell>{client.name}</TableCell>
              <TableCell>{client.email}</TableCell>
              <TableCell>{client.service}</TableCell>
              <TableCell>{client.office}</TableCell>
              <TableCell>{client.date}</TableCell>
              <TableCell>
                <IconButton onClick={() => onEdit(client)}>
                  <EditIcon color="primary" />
                </IconButton>
                <IconButton onClick={() => onDelete(client.id)}>
                  <DeleteIcon color="secondary" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClientList;
