import React, { useState } from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import AddClientDialog from "./AddClientDialog";
import EditClientDialog from "./EditClientDialog";
import ClientList from "./ClientList";
import Header from "./Header";
import Footer from "./Footer";

const Dashboard = () => {
  const [clients, setClients] = useState([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  const handleAddDialogOpen = () => {
    setIsAddDialogOpen(true);
  };

  const handleAddDialogClose = () => {
    setIsAddDialogOpen(false);
  };

  const handleEditDialogOpen = (client) => {
    setSelectedClient(client);
    setIsEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
  };

  const addClient = (clientData) => {
    const updatedClients = [...clients, { ...clientData, id: clients.length + 1 }];
    setClients(updatedClients);
    localStorage.setItem("clients", JSON.stringify(updatedClients));
    handleAddDialogClose();
  };

  const updateClient = (updatedClient) => {
    const updatedClients = clients.map((client) =>
      client.id === updatedClient.id ? updatedClient : client
    );
    setClients(updatedClients);
    localStorage.setItem("clients", JSON.stringify(updatedClients));
    handleEditDialogClose();
  };

  const deleteClient = (clientId) => {
    const updatedClients = clients.filter((client) => client.id !== clientId);
    setClients(updatedClients);
    localStorage.setItem("clients", JSON.stringify(updatedClients));
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Container sx={{ flex: 1 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={4}>
          <Typography variant="h4">Client Portal Dashboard</Typography>
          <Button variant="contained" color="primary" onClick={handleAddDialogOpen}>
            Add New Client
          </Button>
        </Box>
        <ClientList clients={clients} onEdit={handleEditDialogOpen} onDelete={deleteClient} />
        <AddClientDialog
          isOpen={isAddDialogOpen}
          onClose={handleAddDialogClose}
          addClient={addClient}
        />
        {selectedClient && (
          <EditClientDialog
            isOpen={isEditDialogOpen}
            onClose={handleEditDialogClose}
            onSave={updateClient}
            client={selectedClient}
          />
        )}
      </Container>
      <Footer />
    </Box>
  );
};

export default Dashboard;
